import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

type FormProps = {
  shippingAddress: string;
  shippingZip: string;
  shippingCity: string;
  shippingState: string;
  shippingCountry: string;
  shippingRegion: string;
  billingAddress: string;
  billingZip: string;
  billingCity: string;
  billingState: string;
  billingCountry: string;
};

const AddressDetails = ({ step, setStep, formData, setFormData }: any) => {
  const defaultValues: FormProps = {
    shippingAddress: formData.shippingAddress || "",
    shippingZip: formData.shippingZip || "",
    shippingCity: formData.shippingCity || "",
    shippingState: formData.shippingState || "",
    shippingCountry: formData.shippingCountry || "",
    billingAddress: formData.billingAddress || "",
    billingZip: formData.billingZip || "",
    billingCity: formData.billingCity || "",
    billingState: formData.billingState || "",
    billingCountry: formData.billingCountry || "",
    shippingRegion: formData.shippingRegion || "",
  };

  const { t } = useTranslation();

  const validateText = (value: string) => {
    // letters only
    const regex = /^[A-Za-z\s]+$/;
    return (
      regex.test(value) ||
      t("adminRoute.distributorsPage.addressDetails.characterAllowed")
    );
  };

  const onSubmit = (values: FormProps) => {
    console.log(values);
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  return (
    <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
      <div className="w-full flex flex-col gap-6 py-4 ">
        <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          {t("adminRoute.distributorsPage.addressDetails.shippingAddressTitle")}
        </h6>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-1 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingAddress"
                placeholder={t('adminRoute.distributorsPage.addressDetails.placeholders.address')}
                required
                fullWidth
                validation={{
                  validate: (value) =>
                    value.trim() !== "" ||
                    t(
                      "adminRoute.distributorsPage.addressDetails.shippingAddressEmpty"
                    ),
                }}
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingZip"
                placeholder={t('adminRoute.distributorsPage.addressDetails.placeholders.zipCode')}
                required
                type="number"
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingCity"
                placeholder={t('adminRoute.distributorsPage.addressDetails.placeholders.city')}
                required
                fullWidth
                validation={{
                  validate: validateText,
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingState"
                placeholder={t('adminRoute.distributorsPage.addressDetails.placeholders.state')}
                required
                fullWidth
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingCountry"
                placeholder={t('adminRoute.distributorsPage.addressDetails.placeholders.country')}
                required
                fullWidth
                validation={{
                  validate: validateText,
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="shippingRegion"
                placeholder={t('adminRoute.distributorsPage.addressDetails.placeholders.region')}
                required
                fullWidth
                validation={{
                  validate: validateText,
                }}
              />
            </div>
          </div>
        </div>
        <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          {t("adminRoute.distributorsPage.addressDetails.billingAddressTitle")}
        </h6>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-1 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingAddress"
                placeholder={t(
                  "adminRoute.distributorsPage.addressDetails.placeholders.address"
                )}
                required
                fullWidth
                validation={{
                  validate: (value) =>
                    value.trim() !== "" ||
                    t(
                      "adminRoute.distributorsPage.addressDetails.billingAddressEmpty"
                    ),
                }}
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingZip"
                placeholder={t(
                  "adminRoute.distributorsPage.addressDetails.placeholders.zipCode"
                )}
                required
                type="number"
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingCity"
                placeholder={t(
                  "adminRoute.distributorsPage.addressDetails.placeholders.city"
                )}
                required
                fullWidth
                validation={{
                  validate: validateText,
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingState"
                placeholder={t(
                  "adminRoute.distributorsPage.addressDetails.placeholders.state"
                )}
                required
                fullWidth
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="billingCountry"
                placeholder={t(
                  "adminRoute.distributorsPage.addressDetails.placeholders.country"
                )}
                required
                fullWidth
                validation={{
                  validate: validateText,
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-between items-center mt-6 gap-4">
          <button
            className="rounded bg-primary py-2 px-8 md:px-16 poppins text-white w-full md:w-fit "
            type="button"
            onClick={() => setStep(step - 1)}
          >
            {t("adminRoute.distributorsPage.addressDetails.prevButton")}
          </button>
          <button
            className="rounded bg-primary py-2 px-8 md:px-16 poppins text-white w-full md:w-fit "
            type="submit"
          >
            {t("adminRoute.distributorsPage.addressDetails.nextButton")}
          </button>
        </div>
      </div>
    </FormContainer>
  );
};

export default AddressDetails;
