import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  SelectElement,
  DatePickerElement
} from "react-hook-form-mui";
import { callAxios } from "../../../../utils/axios";
import { toastMessage } from "../../../../utils/toast";
import { useTranslation } from "react-i18next";

type FormProps = {
  batchNumber: string;
  price: number;
  quantity: number;
  manufacturingDate: string;
  warrantyPeriodFromBilling: string;
};

const BatchDetails = ({ step, setStep, formData, setFormData }: any) => {

  const {t} = useTranslation()

  const defaultValues: FormProps = {
    batchNumber: "",
    price: undefined,
    quantity: undefined,
    manufacturingDate: "",
    warrantyPeriodFromBilling: "",
  };
  const onSubmit = (values: FormProps) => {
    console.log(values);
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  return (
    <div className="w-full flex flex-col gap-6 py-4 ">
      <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
      {t('adminRoute.createProductPage.batchDetails.batchDetailsTitle')}
      </h6>
      <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="batchNumber"
                placeholder={t('adminRoute.createProductPage.batchDetails.batchNumber')}
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="price"
                placeholder={t('adminRoute.createProductPage.batchDetails.price')}
                required
                type="number"
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="quantity"
                placeholder={t('adminRoute.createProductPage.batchDetails.quantity')}
                required
                type="number"
                fullWidth
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1 -mt-7">
              <label htmlFor="manufacturingDate">Manufacturing Date</label>
              <TextFieldElement
                name="manufacturingDate"
                placeholder={t('adminRoute.createProductPage.batchDetails.manufacturingDate')}
                // label="Manufacturing Date"
                required
                type="date"
                fullWidth
                // label="Manufacturing Date"
                
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="warrantyPeriodFromBilling"
                placeholder={t('adminRoute.createProductPage.batchDetails.warrantyPeriodFromBilling')}
                required
                type="number"
                fullWidth
              />
            </div>
        
          </div>
          <div className="flex justify-between">
          <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            onClick={() => setStep(step - 1)}
          >
          {t('adminRoute.createProductPage.batchDetails.editButton')}
          </button>
          <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            type="submit"
          >
          {t('adminRoute.createProductPage.batchDetails.nextButton')}
          </button>
          </div>
        </div>
      </FormContainer>
    </div>
  );
};

export default BatchDetails;
