import React, { useState, useEffect } from "react";
// import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
// import { callAxios } from "../../../utils/axios";
// import { toastMessage } from "../../../utils/toast";
import moment from "moment";
// import UsersList from "./UsersList";
// import UserChat from "./UserChat";
import { useTranslation } from "react-i18next";
import Sidebar from "../Layout/Sidebar";
import ChatList from "./ChatList";
import Chat from "./Chat";

const CustomerChat = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null as any);
  console.log("selectedUser", selectedUser);  
  const {t} = useTranslation()

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
        <div className="py-6 px-4 flex flex-col gap-8">
         
          <div className="w-full bg-white grid grid-cols-12 gap-8 p-4 rounded shadow">
            <div className="w-full col-span-4">
              <ChatList
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            </div>
            <div className="w-full col-span-8">
              <Chat
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            </div>
          </div>
        </div>
    </>
  );
};

export default CustomerChat;
