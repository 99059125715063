import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBO0KnHgPxIyLM4THx0PTXaE4B7J7sW1tw",
  authDomain: "ai-robotics-3ca13.firebaseapp.com",
  projectId: "ai-robotics-3ca13",
  storageBucket: "ai-robotics-3ca13.appspot.com",
  messagingSenderId: "100100786930",
  appId: "1:100100786930:web:c7082b9646990741bc9e6b"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
