import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import moment from "moment";
import UsersList from "./UsersList";
import UserChat from "./UserChat";
import { useTranslation } from "react-i18next";

const AdminChat = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null as any);
  console.log("selectedUser", selectedUser);  
  const {t} = useTranslation()

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
              {t('adminRoute.adminChat.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
              {t('adminRoute.adminChat.description')}</p>
            </div>
          </div>
          <div className="w-full bg-white grid grid-cols-12 gap-8 p-4 rounded shadow">
            <div className="w-full col-span-3">
              <UsersList
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            </div>
            <div className="w-full col-span-9">
              <UserChat
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default AdminChat;
