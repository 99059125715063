import React, { useState, useEffect } from "react";
import Sidebar from "../../Layout/Sidebar";
import Loading from "react-fullscreen-loading";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useLocation, useNavigate } from "react-router-dom";
import { callAxios } from "../../../utils/axios";
import { toastMessage } from "../../../utils/toast";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const CustomTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff",
  fontWeight: "bold",
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
  color: "black",
}));

const CustomTableBodyCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#F4F4F4",
  fontFamily: "Poppins",
  fontSize: "16px",
  lineHeight: "24px",
  borderBottom: "none",
}));

const CustomTableHeadRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#E0E0E0",
  },
  border: "none",
}));

const CustomTableBodyRow = styled(TableRow)(({ theme }) => ({
  marginBottom: "10px",
}));

const CategoryTable = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const { t } = useTranslation();

  const getAllCategories = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "category/getAllCategories");
      setCategories(data?.categories);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  const handleDelete = async (id: any) => {
    try {
      await callAxios("delete", `category/${id}`);
      toastMessage("Category deleted successfully.", "success");
      getAllCategories(); // Refresh the categories
    } catch (error) {
      toastMessage(
        error?.response?.data?.message || "Failed to delete category.",
        "error"
      );
    }
  };

  const handleEdit = (id, name) => {
    console.log(id);
    navigate(`/admin/create-category/${id}`, { state: { categoryName: name } }); // Pass name via state
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-4 flex flex-col gap-8 hbjh">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t("adminRoute.productCategoryPage.title")}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {t("adminRoute.productCategoryPage.description")}
              </p>
            </div>
            <button
              className="flex gap-1 h-8 px-2 items-center justify-center bg-white rounded-lg poppins leading-6 "
              style={{
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.20)",
              }}
              onClick={() => navigate("/admin/create-category")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.1094 7V17M17.1094 12L7.10938 12"
                  stroke="#4B4B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="wrap break-words">
                {t("adminRoute.productCategoryPage.createButton")}
              </p>
            </button>
          </div>
          <div className="w-full flex flex-col gap-3 ">
            <div className="w-full bg-white p-3 rounded shadow">
              <Table
                sx={{
                  borderSpacing: "0 15px !important",
                  borderCollapse: "separate !important",
                }}
              >
                <TableHead>
                  <CustomTableHeadRow>
                    <CustomTableHeadCell>
                      {t("adminRoute.productCategoryPage.tableHeaders.name")}
                    </CustomTableHeadCell>
                    <CustomTableHeadCell>Actions</CustomTableHeadCell>
                  </CustomTableHeadRow>
                </TableHead>
                <TableBody>
                  {categories?.map((category) => (
                    <CustomTableBodyRow key={category?._id}>
                      <CustomTableBodyCell>
                        {category?.name}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <button
                          onClick={() =>
                            handleEdit(category?._id, category?.name)
                          } // Call the edit function here
                          className="text-blue-500 hover:underline"
                        >
                          <EditIcon
                           sx={{
                            color: 'primary.main', 
                            cursor: 'pointer',
                          }}
                        />
                        </button>
                        <button
                          onClick={() => handleDelete(category?._id)} // Call the delete function here
                          className="text-red-500 hover:underline ml-2"
                        >
                          <DeleteIcon
                            sx={{
                              color: "red",
                              fontSize: 24,
                              cursor: "pointer",
                            }}
                          />
                        </button>
                      </CustomTableBodyCell>
                    </CustomTableBodyRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CategoryTable;
