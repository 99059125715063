import React, { useState, useEffect } from "react";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import { callAxios } from "../../../utils/axios";
import GeneralDetails from "./EditForm/GeneralDetails";
import AddressDetails from "./EditForm/AddressDetails";
import PaymentDetails from "./EditForm/PaymentDetails";
import { useParams } from "react-router-dom";
import { t } from "i18next";

const EditDistributor = () => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [existingData, setExistingData] = useState(null);
  const navigate = useNavigate();
  const { userId } = useParams();
  console.log(userId);

  console.log(formData);

  const getUserById = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "get",
        `user/getDistributorById/${userId}`
      );
      console.log(data);
      setExistingData(data?.user?.distributor);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    if (userId) {
      getUserById();
    }
  }, [userId]);
  console.log(existingData);

  const finalSubmit = async (formValues: any) => {
    const values = { ...formData, ...formValues };
    values.distributorId = existingData?._id;

    try {
      setLoading(true);

      const { data } = await callAxios(
        "put",
        `user/editDistibutorByAdmin/${userId}`,
        values
      );
      console.log(data);
      toastMessage(t("adminRoute.distributorsPage.General.updatedSuccessfully"), "success");
      setLoading(false);
      navigate("/admin/all-distributors");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight ">
                {t('adminRoute.distributorsPage.General.step1Title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {t("adminRoute.distributorsPage.General.step1Description")}
              </p>
            </div>
          </div>
          <div className="w-full bg-[#fff] flex flex-col p-4 gap-8 rounded ">
            <div className="w-full flex justify-between py-2 rounded-lg px-8 align-items bg-white shadow-xl ">
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 1 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  1
                </p>
                <p className="poppins font-medium ">{t("adminRoute.distributorsPage.General.generalDetails")}</p>
              </div>
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 2 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  2
                </p>
                <p className="poppins font-medium ">{t('adminRoute.distributorsPage.General.addressDetails')}</p>
              </div>
              <div className="flex gap-4 items-center ">
                <p
                  className={` rounded-full poppins font-bold px-3 py-1 text-sm text-white ${
                    step === 3 ? "bg-primary " : "bg-[#9E9E9E]"
                  }  `}
                >
                  3
                </p>
                <p className="poppins font-medium ">{t('adminRoute.distributorsPage.General.paymentDetails')}</p>
              </div>
            </div>
            {existingData && (
              <>
                {step === 1 && (
                  <GeneralDetails
                    step={step}
                    setStep={setStep}
                    formData={formData}
                    setFormData={setFormData}
                    existingData={existingData}
                  />
                )}
                {step === 2 && (
                  <AddressDetails
                    step={step}
                    setStep={setStep}
                    formData={formData}
                    setFormData={setFormData}
                    existingData={existingData}
                  />
                )}
                {step === 3 && (
                  <PaymentDetails
                    step={step}
                    setStep={setStep}
                    formData={formData}
                    setFormData={setFormData}
                    finalSubmit={finalSubmit}
                    existingData={existingData}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default EditDistributor;
