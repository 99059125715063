import React, { useEffect, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import axios from "axios";
import { backendUrl } from "../../utils/axios";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useAppDispatch } from "../../store/hooks";
import { login } from "../../store/slices/user";
type FormProps = {
  email: string;
  password: string;
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [values, setValues] = useState<FormProps>();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [defaultValues, setDefaultValues] = useState({
    email: localStorage.getItem("savedEmail") || "",
    password: localStorage.getItem("savedPassword") || "",
  });

  useEffect(() => {
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");
    if (savedEmail) {
      setDefaultValues({ email: savedEmail, password: savedPassword || "" });
    }
  }, []);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const onSubmit = async (values: FormProps) => {
    console.log(values);
    if (/\s/.test(values.password)) {
      toastMessage("Space is not allowed in password", "error");
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.post(`${backendUrl}/auth/login`, values);
      console.log(data);
      dispatch(login(data));
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      if (rememberMe) {
        localStorage.setItem("savedEmail", values.email);
        localStorage.setItem("savedPassword", values.password);
      } else {
        localStorage.removeItem("savedEmail");
        localStorage.removeItem("savedPassword");
      }
      setLoading(false);
      toastMessage("Login Successfully", "success");

      navigate(
        data.user.createdByRole === "Admin"
          ? "/admin/dashboard"
          : "/distributor/dashboard "
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  // const defaultValues: FormProps = { email: "", password: "" };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <div className="w-full grid grid-cols-12 h-full">
        <div className="hidden lg:flex col-span-7 w-full">
          <img
            src="/assets/Images/login-bg.png"
            className="w-full min-h-[90vh] h-[100vh] object-cover"
          />
        </div>
        <div className="w-full bg-secondary items-center justify-center col-span-12 lg:col-span-5 flex h-screen">
          <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
            <div className="w-full max-w-md flex flex-col gap-8 ">
              <div className="flex flex-col gap-3">
                <h2 className="poppins text-2xl font-bold leading-8 tracking-tight ">
                  Account Login
                </h2>
                <p className="poppins text-[#8692A6] leading-6 ">
                  Login with your official email address and password{" "}
                </p>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-3">
                  <label
                    className="poppins text-[#0196D0] text-lg font-medium "
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <TextFieldElement
                    name="email"
                    type="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label
                    className="poppins text-[#0196D0] text-lg font-medium "
                    htmlFor="email"
                  >
                    Password
                  </label>
                  <TextFieldElement
                    name="password"
                    size="small"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="flex justify-end">
                  <p
                    className="text-[#696F79] poppins text-sm font-medium cursor-pointer "
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forget Password?
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex gap-2 items-center ">
                  <input
                    type="checkbox"
                    className="w-4 h-4 cursor-pointer"
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                    }}
                  />
                  <p className="text-[#696F79] poppins text-lg font-medium ">
                    Remember me
                  </p>
                </div>
                <button
                  type="submit"
                  className="bg-[#0196D0] rounded-lg w-full py-3 text-[#F8F8F8] poppins text-lg font-semibold  "
                >
                  Login
                </button>
              </div>
            </div>
          </FormContainer>
        </div>
      </div>
    </>
  );
};

export default Login;
