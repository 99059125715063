import React, { useState, useEffect } from "react";
import {
  AutocompleteElement,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { countries } from "../../../../constants/countryCode";

type FormProps = {
  name: string;
  ownerFirstName: string;
  ownerLastName: string;
  phone: string;
  email: string;
};

const GeneralDetails = ({ step, setStep, formData, setFormData }: any) => {
  const defaultValues: FormProps = {
    name: formData.name || "",
    ownerFirstName: formData.ownerFirstName || "",
    ownerLastName: formData.ownerLastName || "",
    phone: formData.phone || "",
    email: formData.email || "",
  };

  const { t } = useTranslation();

  const onSubmit = (values: FormProps) => {
    console.log(values);
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  const preventNumberInput = (e) => {
    const key = e.key;
    if (/^[0-9]$/.test(key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="w-full flex flex-col gap-6 py-4 ">
      <h6 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
        {t("adminRoute.distributorsPage.generalDetails.pageTitle")}
      </h6>
      <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
        <div className="flex flex-col gap-8 w-full">
          <div className="w-full grid grid-cols-3 gap-16 ">
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="name"
                placeholder={t(
                  "adminRoute.distributorsPage.generalDetails.placeholders.distributorName"
                )}
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="ownerFirstName"
                placeholder={t(
                  "adminRoute.distributorsPage.generalDetails.placeholders.ownerFirstName"
                )}
                required
                fullWidth
                inputProps={{
                  onKeyDown: preventNumberInput,
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="ownerLastName"
                placeholder={t(
                  "adminRoute.distributorsPage.generalDetails.placeholders.ownerLastName"
                )}
                required
                fullWidth
                inputProps={{
                  onKeyDown: preventNumberInput,
                }}
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-3 gap-16   ">
            <div className="w-full flex flex-col gap-1">
              <AutocompleteElement
                name="countryCode"
                required
                label={t(
                  "adminRoute.distributorsPage.generalDetails.placeholders.countryCode"
                )}
                options={countries}
                autocompleteProps={{
                  fullWidth: true,
                  placeholder: t(
                    "adminRoute.distributorsPage.generalDetails.placeholders.countryCode"
                  ),
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="phone"
                placeholder={t(
                  "adminRoute.distributorsPage.generalDetails.placeholders.phoneNumber"
                )}
                type="number"
                required
                fullWidth
                validation={{
                  validate: (value) => {
                    let regex = /^[0-9]{10}$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return t(
                        "adminRoute.distributorsPage.generalDetails.validationMessages.phone"
                      );
                    }
                  },
                }}
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="email"
                placeholder={t(
                  "adminRoute.distributorsPage.generalDetails.placeholders.email"
                )}
                type="email"
                required
                fullWidth
              />
            </div>
            <div className="w-full flex flex-col gap-1">
              <TextFieldElement
                name="frenchIdentificationNumber"
                placeholder={t(
                  "adminRoute.distributorsPage.generalDetails.placeholders.frenchIdentificationNumber"
                )}
                fullWidth
                validation={{
                  validate: (value) => {
                    let regex = /^\d{13}(-\d{3})?$/;
                    if (value && value.includes(" ")) {
                      return t(
                        "adminRoute.distributorsPage.generalDetails.placeholders.frenchIdentificationNumberNotContainSpace"
                      );
                    }
                  },
                }}
              />
            </div>
          </div>
          <button
            className="rounded bg-primary py-2 px-16 poppins text-white w-fit "
            type="submit"
          >
            {t("adminRoute.distributorsPage.generalDetails.nextButton")}
          </button>
        </div>
      </FormContainer>
    </div>
  );
};

export default GeneralDetails;
