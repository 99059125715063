import React, { useState, useEffect } from "react";
import axios from "axios";
import { backendUrl } from "../../utils/axios";

import { useNavigate, useSearchParams } from "react-router-dom";
import { toastMessage } from "../../utils/toast";
import Loading from "react-fullscreen-loading";

import OtpInput from "react-otp-input";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(searchParams.get("token") || "");

  const [timeLeft, setTimeLeft] = useState(60);
  const [timerExpired, setTimerExpired] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [timerVisible, setTimerVisible] = useState(true);

  const handleSubmit = async () => {
    console.log(otp);
    console.log(token);

    if (!otp) {
      toastMessage("Please Enter OTP", "error");
      return;
    } else if (otp.length < 4) {
      toastMessage("Please Enter valid OTP", "error");
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.post(`${backendUrl}/auth/verifyOtp`, {
        otp: Number(otp),
        token,
      });
      console.log(data);
      setLoading(false);
      toastMessage(data.message, "success");
      setTimerStarted(true);
      navigate(`/reset-password?token=${token}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    let timerInterval;

    if (timeLeft > 0) {
      timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); // Update the timer every 1 second
    } else {
      setTimerExpired(true);
      setTimerVisible(false);
    }

    return () => {
      clearInterval(timerInterval); // Clean up the timer interval when component unmounts
    };
  }, [timeLeft]);

  const formattedTimeLeft = timeLeft.toString().padStart(2, "0");

  const resendOtp = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(`${backendUrl}/auth/resendOtp`, {
        token,
      });
      console.log(data);
      setLoading(false);
      toastMessage(data.message, "success");
      setTimerStarted(true);
      setTimerExpired(false);
      setTimeLeft(60);
      setToken(data.token);
      setTimerVisible(true);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#5046E5" />
      <div className="w-full grid grid-cols-12 h-full">
        <div className="flex col-span-7 w-full">
          <img
            src="/assets/Images/login-bg.png"
            className="w-full min-h-[90vh] h-[100vh]"
          />
        </div>
        <div className="w-full bg-secondary items-center justify-center col-span-5 flex ">
          <div className="w-96 flex flex-col gap-8 ">
            <div className="flex flex-col gap-3">
              <h2 className="poppins text-2xl font-bold leading-8 tracking-tight ">
                Enter OTP
              </h2>
              <p className="poppins text-[#8692A6] leading-6 ">
                We have mailed you an OTP on your email address.
              </p>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                containerStyle={{
                  display: "flex",
                  gap: "1rem",
                  width: "100%",
                }}
                inputStyle={{
                  width: "2.5rem",
                  height: "3rem",
                  borderRadius: "6px",
                  border: "1px solid #8692A6",
                  padding: "0.5rem",
                  fontSize: "1.5rem",
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  color: "#000",
                }}
                inputType="tel"
              />
            </div>
            <div className="flex flex-col gap-1">
              <button
                onClick={handleSubmit}
                className="bg-[#0196D0] rounded-lg w-full py-3 text-[#F8F8F8] poppins text-lg font-semibold  "
              >
                Submit
              </button>

              <div className="flex w-full justify-between items-center ">
                <p
                  className={`poppins text-lg leading-8 tracking-tight  ${
                    timerExpired
                      ? "cursor-pointer"
                      : "cursor-not-allowed text-gray-400"
                  }   `}
                  onClick={timerExpired ? resendOtp : () => {}}
                >
                  Resend Otp
                </p>

                {timerVisible && (
                  <p className="text-[#2186F2] poppins text-lg leading-8 tracking-tight ">
                    00:{formattedTimeLeft}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
