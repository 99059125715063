import React, { useState } from "react";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../Layout/Sidebar";
import { callAxios } from "../../../utils/axios";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";

type FormProps = {
  name: string;
};

const CreateCategory = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  // Extract categoryId from URL parameters
  const { id } = useParams(); // Assuming the route is defined to include categoryId
  const location = useLocation();
  const categoryName = location.state?.categoryName;
 console.log(id)
  const defaultValues: FormProps = {
    name: categoryName || "", // Set default value to categoryName if editing
  };

  const onSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      let response;
      if (id) {
        // Edit category
        response = await callAxios("put", `category/${id}`, values);
        toastMessage("Category Updated Successfully", "success");
      } else {
        // Create category
        response = await callAxios("post", "category/createCategory", values);
        toastMessage("Category Created Successfully", "success");
      }
      console.log(response.data);
      setLoading(false);
      navigate("/admin/product-category");
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <Sidebar>
        <div className="py-6 px-8 flex flex-col gap-8">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <h1 className="text-[#23282D] poppins text-3xl font-semibold tracking-tight">
                {id ? t('adminRoute.createCategoryPage.editTitle') : t('adminRoute.createCategoryPage.title')}
              </h1>
              <p className="inter tracking-tight text-sm color-[#23282D]">
                {id ? t('adminRoute.createCategoryPage.description') : t('adminRoute.createCategoryPage.description')}
              </p>
            </div>
          </div>
          <div className="w-full bg-[#fff] flex flex-col p-4 gap-8 rounded">
            <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
              <div className="flex flex-col gap-8 w-full">
                <div className="w-full grid grid-cols-2 gap-16 ">
                  <div className="w-full flex flex-col gap-4">
                    <h6 className="text-[#23282D] text-2xl poppins font-medium">
                      {t('adminRoute.createCategoryPage.formContainer.createCategorySection.title')}
                    </h6>
                    <TextFieldElement
                      name="name"
                      placeholder={t('adminRoute.createCategoryPage.formContainer.createCategorySection.formLabels.name')}
                      required
                      fullWidth
                      validation={{
                        validate: (value) =>
                          value.trim() !== "" ||
                          t(
                            "adminRoute.createCategoryPage.formContainer.categoryNameNotContainSpace"
                          ),
                      }}
                    />
                    <button
                      className="rounded bg-primary py-2 px-16 poppins text-white w-fit"
                      type="submit"
                    >
                      {id ? t('adminRoute.createCategoryPage.formContainer.createCategorySection.editButton') : t('adminRoute.createCategoryPage.formContainer.createCategorySection.submitButton')}
                    </button>
                  </div>
                </div>
              </div>
            </FormContainer>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default CreateCategory;
