import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";

import {
  FormContainer,
  TextFieldElement,
  SelectElement,
} from "react-hook-form-mui";
import { toastMessage } from "../../../utils/toast";
import Loading from "react-fullscreen-loading";
import { callAxios } from "../../../utils/axios";

type FormProps = {
    skuName: string;
    skuCode: string;
    quantity: number;
    status: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};

const ViewProduct = ({ checked, setChecked, setOpen, existingUser }: any) => {
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState([] as any);
  const defaultValues: FormProps = {
    skuName: existingUser?.skuName,
    skuCode: existingUser?.skuCode,
    quantity: existingUser?.quantity,
    status: existingUser?.status,
  };

  console.log(defaultValues)
  const getAllRoles = async () => {
    try {
      setLoading(true);
      const { data } = await callAxios("get", "role/getAllRoles");
      console.log(data);
      setRoleOptions(
        data?.roles.map((item: any) => {
          return {
            id: item._id,
            label: item.name,
          };
        })
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  const onSubmit = async (values: FormProps) => {
    try {
      setLoading(true);
      const { data } = await callAxios(
        "put",
        "user/editUserByAdmin",
        values
      );
      console.log(data);
      toastMessage("User Updated Successfully", "success");
      setLoading(false);
      setChecked(!checked);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastMessage(
        error?.response?.data?.message || "Something went wrong.",
        "error"
      );
    }
  };

  return (
    <Box sx={style}>
      <Loading loading={loading} background="#fff" loaderColor="#719FE5" />
      <div className="flex flex-col gap-4  w-full">
        <h3 className="text-[#23282D] poppins text-2xl font-medium leading-9 tracking-tight">
          View Product
        </h3>
        <p className="text-[#23282D] inter text-sm font-normal leading-5 ">
        Gain insights and details about user profiles, fostering transparency and efficient user oversight for a well-managed and informed community.        </p>
        <FormContainer defaultValues={defaultValues} onSuccess={onSubmit}>
          <div className="w-full flex flex-col gap-8">
            {/* <div className="w-full">
              <TextFieldElement
                name="name"
                label="Name"
                required
                fullWidth
                disabled
                sx={{
                  backgroundColor: "#fff",
                }}
                validation={{
                  validate: (value) => {
                    console.log(value);
                    let regex = /^(?=.*[a-zA-Z]).+$/;
                    if (regex.test(value)) {
                      return true;
                    } else {
                      return "Name must contains a character. ";
                    }
                  },
                }}
              />
            </div> */}
            <div className="w-full">
              <TextFieldElement
                name="skuName"
                label="Sku Name"
                
                required
                disabled
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <div className="w-full">
              <TextFieldElement
                name="skuCode"
                label="Sku Code"
                
                required
                disabled
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <div className="w-full">
              <TextFieldElement
                name="quantity"
                label="Quantity"
                
                required
                disabled
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <div className="w-full">
              <TextFieldElement
                name="status"
                label="Status"
                
                required
                disabled
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                }}
              />
            </div>

           
          
          </div>
        </FormContainer>
      </div>
    </Box>
  );
};

export default ViewProduct;
